import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1e1e2d",
        secondary: "#09565E",
        accent: "#EEC22C",
        error: "#FF5252",
        info: "#09565E",
        success: "#4CAF50",
        warning: "#e8c30f",
        bg: "#f8f9fe",
      },
      dark: {
        primary: "#343957",
      },
    },
  },
  lang: {
    locales: { ru },
    current: "ru",
  },
});
