<template>
  <v-app-bar app color="white">
    <v-app-bar-nav-icon
      @click.stop="$emit('handleNavIconClick')"
    ></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <Profile />
  </v-app-bar>
</template>

<script>
import Profile from "@/components/Profile/Profile.vue";

export default {
  components: { Profile },
};
</script>

<style scoped lang="scss"></style>
