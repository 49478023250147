import api from "@/api";

const state = {
  user: {},
};

const getters = {
  user: (state) => state.user,
};

const actions = {
  init: async ({ dispatch }) => {
    await dispatch("getUser");
  },
  getUser: async ({ commit }) => {
    try {
      const { data } = await api.getUser();
      commit("setUser", data);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setUser: (state, user) => (state.user = user),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
