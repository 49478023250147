export default [
  {
    title: "Главная",
    type: "item",
    icon: "mdi-home",
    to: { path: "/" },
  },
  {
    title: "Диктующие точки",
    type: "group",
    active: true,
    icon: "mdi-home-circle-outline",
    items: [
      {
        title: "Список ДТ",
        type: "item",
        to: { name: "points" },
      },
      {
        title: "Добавить ДТ",
        type: "item",
        to: { name: "PointAddress" },
      },
    ],
  },
];
