<template>
  <v-list-item :to="item.to">
    <v-list-item-icon v-if="item.icon">
      <v-icon> {{ item.icon }} </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ nested ? "—" : "" }} {{ item.title }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
