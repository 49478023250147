import home from "@/apps/home/routes";
import external from "@/apps/external/routes";
import login from "@/apps/login/routes";
import points from "@/apps/points/routes";

export default [
  ...home,
  ...external,
  ...login,
  ...points,
  {
    path: "*",
    redirect: { path: "/" },
  },
];
