import { auth } from "@/services/auth";
import store from "@/store";

let isFirstRequest = true;

export const fetchInit = (to) => {
  if (!isFirstRequest) return;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (auth.loggedIn()) {
      store.dispatch("core$common/init");
      isFirstRequest = false;
    }
  }
};
