<template>
  <v-menu bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on">
        <v-avatar color="secondary" size="30">
          <span class="white--text">U</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar color="secondary">
            <span class="white--text">U</span>
          </v-avatar>
          <h3>{{ user.fullName }}</h3>
          <p class="text-caption mt-1">
            {{ user.email }}
          </p>
          <v-divider class="my-3"></v-divider>
          <v-btn depressed rounded text> Профиль </v-btn>
          <v-divider class="my-3"></v-divider>
          <v-btn depressed rounded text @click="logout"> Выход </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import { auth } from "../../services/auth";

export default {
  props: {
    user: {
      type: Object,
      requried: true,
      default: () => ({}),
    },
  },
  methods: {
    logout() {
      auth.logOut();
    },
  },
};
</script>

<style scoped lang="scss"></style>
