<template>
  <v-app>
    <SideNav v-model="drawer" v-if="showBars" />
    <Header @handleNavIconClick="drawer = !drawer" v-if="showBars" />
    <Content>
      <router-view></router-view>
    </Content>
  </v-app>
</template>

<script>
import SideNav from "@/components/layouts/SideNav/SideNav.vue";
import Header from "@/components/layouts/Header/Header.vue";
import Content from "@/components/layouts/Content/Content.vue";

import publicRoutes from "@/data/publicRoutes";

export default {
  components: { SideNav, Header, Content },
  data() {
    return {
      drawer: true,
      showBars: true,
    };
  },
  methods: {
    checkBarsVisibility(routeName) {
      this.showBars = !publicRoutes.includes(routeName);
    },
  },
  watch: {
    "$route.name"(routeName) {
      this.checkBarsVisibility(routeName);
    },
  },
};
</script>

<style lang="scss">
.input-wrapper {
  display: flex;
  align-items: baseline;
  max-width: 800px;
  padding-left: 12px;
  & > * {
    &:nth-of-type(1) {
      width: 300px;
      font-size: 12px;
    }
    &:nth-of-type(2) {
      width: 100%;
      display: flex;
    }
  }
}
</style>
