<template>
  <v-navigation-drawer
    app
    class="primary"
    :value="value"
    @input="$emit('input', $event)"
    dark
  >
    <v-list nav dense>
      <component
        v-for="(item, i) in menu"
        :key="i"
        :is="getComponent(item.type)"
        :item="item"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import menu from "@/data/menu";
import _getComponent from "@/components/layouts/SideNav/scripts/getComponent";

import SideNavItem from "@/components/layouts/SideNav/components/SideNavItem.vue";
import SideNavGroup from "@/components/layouts/SideNav/components/SideNavGroup.vue";

export default {
  components: { SideNavItem, SideNavGroup },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu,
    };
  },
  methods: {
    getComponent(type) {
      return _getComponent(type);
    },
  },
};
</script>

<style scoped lang="scss"></style>
